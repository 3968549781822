import React, {useEffect} from "react";


import App from "../../routes/index";


import {useHistory, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";


const MainApp = () => {


    const match = useRouteMatch();


    return (
        <div>

            <App match={match}/>

        </div>
    )
};
export default MainApp;

