class Helpers{

getDeviceName(){
    let name = navigator.appCodeName;
    return name.replace(" ","_").toLowerCase();
}

setCookie(name, value, days){
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    //document.cookie = name + "=" + (value || "") + expires + ";";
    document.cookie = name + "=" + (value || "") + expires + "; path=/; domain=.phillipsoutsourcing.net; Secure; HttpOnly; SameSite=None";
    return
}

    static objectFormBuilder(form, object) {


        for (let name in object) {

            if (object.hasOwnProperty(name)) {
                if (object[name] === undefined || object[name] === null || object[name] === "null") {

                    form.append(name, '');
                } else {

                    form.append(name, object[name]);
                }
            }
        }

        return form;
    }

}

export default Helpers;